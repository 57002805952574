/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const n = new Map();
function t() {
  n.clear();
}
function e(t) {
  return n.get(t);
}
function c(t, e) {
  n.set(t, e);
}
function o(t) {
  n.delete(t);
}
export { t as clearVNodeCache, o as deleteVNodeCache, e as getVNodeCache, c as setVNodeCache };