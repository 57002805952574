/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import e from "@esri/arcgis-html-sanitizer";
import t from "../../core/ArrayPool.js";
import { handlesGroup as r, makeHandle as o } from "../../core/handleUtils.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { signalFromSignalOrOptions as n } from "../../core/promiseUtils.js";
import { watch as s, syncAndInitial as i } from "../../core/reactiveUtils.js";
import { waitAnimationFrame as a } from "../../core/scheduling.js";
import { clearVNodeCache as c } from "./vnodeCache.js";
function l(...e) {
  const r = t.acquire();
  for (let t = 0; t < e.length; t++) {
    const o = e[t],
      n = typeof o;
    if ("string" === n) r.push(o);else if (Array.isArray(o)) r.push.apply(r, o);else if ("object" === n) for (const e in o) o[e] && r.push(e);
  }
  const o = r.join(" ");
  return t.release(r), o;
}
const p = (() => {
  const e = new Map(),
    t = new ResizeObserver(t => {
      c();
      for (const r of t) e.get(r.target)?.(r);
    }),
    n = (r, n, s) => (e.set(r, n), t.observe(r, s), o(() => {
      t.unobserve(r), e.delete(r);
    }));
  return (e, t, a) => {
    let c = null;
    return r([s(() => "function" == typeof e ? e() : e, e => {
      c?.remove(), e && (c = n(e, t, a));
    }, i), o(() => c?.remove())]);
  };
})();
function u(e) {
  const t = e?.closest("[dir]");
  return null !== t && t instanceof HTMLElement && "rtl" === t.dir || "rtl" === document.dir;
}
function f(e) {
  const t = "data-node-ref";
  this[e.getAttribute(t)] = null;
}
function m(e) {
  const t = "data-node-ref";
  this[e.getAttribute(t)] = e;
}
const d = ["dd", "dl", "dt", "h1", "h2", "h3", "h4", "h5", "h6", "sub", "sup", ...["animate", "animatetransform", "circle", "clippath", "defs", "ellipse", "g", "image", "line", "lineargradient", "marker", "mask", "path", "pattern", "polygon", "polyline", "radialgradient", "rect", "stop", "svg", "switch", "symbol", "text", "textpath", "tspan", "use"]],
  g = d.reduce((e, t) => (e[t] = [], e), {}),
  h = ["align", "alink", "alt", "bgcolor", "border", "cellpadding", "cellspacing", "class", "color", "cols", "colspan", "coords", "d", "dir", "face", "height", "hspace", "ismap", "lang", "marginheight", "marginwidth", "multiple", "nohref", "noresize", "noshade", "nowrap", "ref", "rel", "rev", "rows", "rowspan", "scrolling", "shape", "span", "summary", "tabindex", "title", "usemap", "valign", "value", "vlink", "vspace", "width"],
  y = new e({
    whiteList: g,
    onTagAttr: (e, t, r) => {
      const o = `${t}="${r}"`;
      if (h.includes(t)) return o;
    },
    stripIgnoreTag: !0,
    stripIgnoreTagBody: ["script", "style"]
  }, !0);
function v(e) {
  return "Enter" === e || " " === e;
}
async function w(e, t) {
  await a(n(t));
  const r = "function" == typeof e ? e() : e;
  r && ("setFocus" in r && "function" == typeof r.setFocus ? await r.setFocus() : r instanceof HTMLElement && r.focus());
}
export { d as additionalAllowedTags, l as classes, f as discardNode, v as isActivationKey, u as isRTL, p as onResize, y as renderingSanitizer, h as safeAttrs, w as setFocus, m as storeNode };